@import url("https://fonts.googleapis.com/css?family=Oswald:300,400");
@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@300&family=Kanit&family=Roboto+Slab&display=swap');


  .smallwaves{
    height:50px;margin-top:-50px;
  }

*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(161, 161, 161);
    border-radius: 20px;
}
section {
    margin: 20px auto;
}
.bg-gray-101{
    background-color: #f2f2f2 !important;
}
.mbl{
    height: 70vh !important;
    overflow: auto;
}

.platlists{
    background: #f4f4f4;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, .125);
    border-radius: 1rem;
}

.icon-shapes{
    width: 30px!important;
    height: 30px !important;
}

h2 {
    font-family: "Oswald", sans-serif;
}
.searches{
    max-height: 250px!important;
    overflow: auto;
}
.sr{
    position: absolute;
    z-index: 1000;
    opacity: 0;
}
.z-index-max{
    z-index : 99999;
}



.searchresult{
    position: absolute;
    z-index: 1000;
    top: 0;
    min-width: 11rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: .875rem;
    color: #67748e;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid transparent;
    border-radius: 0.5rem;
    cursor: pointer;
    opacity: 1!important;
    visibility: visible!important;
    transform: perspective(999px) rotateX(0deg) translateZ(0) translate3d(0, 37px, 5px)!important;
    box-shadow: 0 8px 26px -4px hsl(0deg 0% 8% / 15%), 0 8px 9px -5px hsl(0deg 0% 8% / 6%);
}
#searchvalue{
    outline: none !important;
    box-shadow: none !important;
    border-radius: 0px;
    border-bottom: 2px solid #000 !important;
    border: 0;
    background-color: transparent;
}
#searchvalue::placeholder{
    color :rgb(44, 44, 44)
}
#searchvalue:focus {
    outline: none!important;
    border-bottom: 2px solid rgb(255, 102, 63) !important;
}
#hero-header{
    background-image: url(../img/bg.png)
}
.justify-space-between{
    justify-content: space-between;
}
.video_thumbnail.active{
    cursor: pointer;
    border: 1px solid #ff6f0087;
    background-color: rgb(255, 255, 255);
    border-radius: 14px;
    box-shadow: 0 0.25rem 0.375rem -0.0625rem hsl(0deg 0% 0% / 47%), 0 0.125rem 0.25rem -0.0625rem hsl(0deg 0% 23% / 53%) !important;
    img {
            transform: scale(1.1);
        }
    .playing::after {
        content: "Now Playing"; // ::before and ::after both require content
        top: 0;
        left: 0;
        height: 100%;
        opacity: .7;
    }
    .playing{
        *{
            display: none;
        }
    }
}
.video_thumbnail:hover{
    cursor: pointer;
    background-color: rgb(255, 255, 255);
    border-radius: 14px;
    box-shadow: 0 0.25rem 0.375rem -0.0625rem hsl(0deg 0% 0% / 47%), 0 0.125rem 0.25rem -0.0625rem hsl(0deg 0% 23% / 53%) !important;
    img {
        transform: scale(1.1);
    }
}

.video_thumbnails:hover{
    cursor: pointer;
    translate: 0 -14px;
    background-color: rgb(255, 255, 255);
    border-radius: 14px;
    box-shadow:  0 0.25rem 0.375rem -0.0625rem hsl(0deg 0% 0% / 47%),   0 0.125rem 0.25rem -0.0625rem hsl(0deg 0% 23% / 53%) !important;
}
.showcaseimg{
    min-height: 140px;
    min-width: 100%;
    background-position: center!important;
    background-repeat: no-repeat!important;
    background-size: cover!important;
}
.video_thumbnail:hover img{
    transform: scale(1.3);
}
/* #full_video{
    height: 700px!important;
}

@media only screen and (max-width: 769px) {
    #full_video {
        height: 300px!important;
    }
} */


.f-xh{
    height: 90px;
}

.loding{
    display: flex;
    flex-direction: column;
        height: 100vh;
        justify-content: center;
        align-items: center;
}

.bar {
    background-color: #fce0de;
    min-width: 300px;
    min-height: 5px;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

.bar::after {
    content: "";
    min-height: 5px;
    position: absolute;
    background: red;
    transform: translateX(-100%);
    animation: animate 3s infinite;
}

.bar::before {
    content: "";
    min-height: 5px;
    position: absolute;
    background: red;
    transform: translateX(-100%);
    animation: animate 3s ease-out infinite;
    animation-delay: 1s;
    z-index: 5;
}

.by {
    position: fixed;
    bottom: 10px;
    right: 10px;
    font-family: "roboto";
    font-size: 20px;
}

@keyframes animate {
    0% {
        transform: translateX(-100%);
        min-width: 10px;
    }

    100% {
        transform: translateX(300%);
        min-width: 400px;
    }
}

.internetstat {
    bottom: 4% !important;
    display: none;
}



.internetstat.offline {
    h2 {
        color: red !important;
    }
    .wifisignal {
        padding: 4px;

        g {
            path {
                fill: red;
            }
        }
    }
}

.internetstat.online {
    left: 2%;
    background-color: #c8fbc9 !important;
    display: block;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    h2{
        color: green !important;
    }

    .wifisignal {
        padding: 4px;

        g {
            path {
                fill: rgb(14, 255, 5);
            }
        }
    }
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.internetstat.offline{
    background-color: #fbc8c8 !important;
    display: block;
    bottom: unset!important;
    -webkit-animation: moveX 15s linear 0s infinite alternate, moveY 15.15s linear 0s infinite alternate;
    -moz-animation: moveX 15s linear 0s infinite alternate, moveY 15.15s linear 0s infinite alternate;
    -o-animation: moveX 15s linear 0s infinite alternate, moveY 15.15s linear 0s infinite alternate;
    animation: moveX 15s linear 0s infinite alternate, moveY 15.15s linear 0s infinite alternate;
}

@-webkit-keyframes moveX {
    from {
        left: 0;
    }

    to {
        left: 90%;
    }
}

@-moz-keyframes moveX {
    from {
        left: 0;
    }

    to {
        left: 90%;
    }
}

@-o-keyframes moveX {
    from {
        left: 0;
    }

    to {
        left: 90%;
    }
}

@keyframes moveX {
    from {
        left: 0;
    }

    to {
        left: 90%;
    }
}

@-webkit-keyframes moveY {
    from {
        top: 0;
    }

    to {
        top: 95%;
    }
}

@-moz-keyframes moveY {
    from {
        top: 0;
    }

    to {
        top: 95%;
    }
}

@-o-keyframes moveY {
    from {
        top: 0;
    }

    to {
        top: 95%;
    }
}

@keyframes moveY {
    from {
        top: 0;
    }

    to {
        top: 95%;
    }
}


.blockeroverlay{
    width: 100%;
    opacity: 0;  
    display: none;
    position: fixed;
    height: 100vh;
    background: rgb(31 180 213 / 76%);
}

 .wrapper {
     position: absolute;
     max-width: 480px;
     top: 50%;
     left: 50%;
     width: 100%;
     padding: 40px 30px;
     background: #fff;
     border-radius: 15px;
     opacity: 0;
     pointer-events: none;
     transform: translate(-50%, -50%) scale(1.2);
     box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.06);
     transition: opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
 }

.wrapper.show {
    opacity: 1;
    z-index: 9999;
    pointer-events: auto;
    transform: translate(-50%, -50%) scale(1);
}
.blockeroverlay.show {
    display: block!important;
    opacity: 1;
    z-index: 9999;
    pointer-events: auto;
}

.wrapper .content,
.content .warn-icon,
.warn-icon .icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper .content {
    flex-direction: column;
}

.content .warn-icon {
    height: 115px;
    width: 115px;
    border-radius: 50%;
    background: linear-gradient(#9b27ca 0%, #9927cf 0%, #d33639 100%, #f92121 100%);
}

.warn-icon .icon {
    height: 100px;
    width: 100px;
    background: #fff;
    border-radius: inherit;
}

.warn-icon .icon i {
    background: linear-gradient(#9b27ca 0%, #9927cf 0%, #d33639 100%, #f92121 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
}

.content h2 {
    margin-top: 35px;
    font-size: 32px;
}

.content p {
    font-size: 19px;
    text-align: center;
    margin-top: 20px;
}

.interactions{
    h4,button{
        margin-left: 20px;
    }
    button{
        background-color: #dbdbdb;
        color: #000;
    }
}

.tags{
    span{
        margin-right: 12px;
        border-radius: 50px;
        font-size: 0.8rem;
    }
}


.typing-area {
    padding: 18px 30px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    input {
        height: 45px;
        width: calc(100% - 58px);
        font-size: 16px;
        padding: 0 13px;
        border: 1px solid #e6e6e6;
        outline: none;
        border-radius: 5px 0 0 5px;
    }
    button {
        color: #fff;
        width: 55px;
        border: none;
        outline: none;
        background: #333;
        font-size: 19px;
        cursor: pointer;
        opacity: 0.7;
        border-radius: 0 5px 5px 0;
        transition: all 0.3s ease;
    }
}

.autoplay{
    position: absolute;
    background: rgba(56, 56, 56, 0.822);
    display: none;
    .next {
        width: 30%;
        height: 30%;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        #next_title{
            background-color: rgba(0, 0, 0, 0.603);
            height: 100%;
            color: #fff;
            margin: 0;
            border-radius: inherit;
        }
    }
    
}
.autoplay.activate{
    z-index: 20;
    display: flex;
    align-items: end;
    justify-content: end;
    padding: 50px;
    
}
